"use client";
/* global */
import Bugsnag from "@bugsnag/js";
import {sendGTMEvent} from "@next/third-parties/google";
import {
    CartView,
    Channel,
    GiftCardView,
    Lookup,
    OfferingView,
    PlaceView,
    SaleView,
    SubscriptionView,
    UserView,
} from "@shared/types/api-types";
import {SearchableCollections} from "@app/poiscaille-types";

import {TrackingData} from "@shared/types/editorial-types";

import {RestrictedChannel} from "../const";
import _ from "./Reference";

type DataLayerEvent =
    | "screenInteraction"
    | "addToCart"
    | "removeFromCart"
    | "checkout"
    | "orderComplete"
    | "unsubscribe";

type DataLayerObject =
    | {
          event: DataLayerEvent;
          [key: string]: any;
      }
    | DataLayerEventSearch
    | DataLayerEventSubmitForm
    | DataLayerEventSignUp
    | DataLayerEventLogin
    | DataLayerEventLogout
    | DataLayerEventConfirmAuthentication
    | DataLayerEventResetAuthentication
    | DataLayerEventClickLink
    | DataLayerEventViewModal;

type DataLayerEventSearch = {
    event: "search";
    search_term: string;
} & (
    | {
          search_type: "collection";
          search_collection: keyof SearchableCollections;
      }
    | {
          search_type: "map";
      }
);

type DataLayerEventSubmitForm = {
    event: "generate_lead";
    currency?: "EUR";
    value?: number;
    form_reference: string;
    container: TrackingData["container"] | null;
};

type DataLayerEventSignUp = {
    event: "sign_up";
    method?: "email";
    user: DataLayerUser;
};

type DataLayerEventLogin = {
    event: "login";
    method?: "email";
    user: DataLayerUser;
};

type DataLayerEventLogout = {
    event: "logout";
    user: DataLayerUser;
};

type DataLayerEventConfirmAuthentication = {
    event: "confirm_authentication";
    user: DataLayerUser;
};

type DataLayerEventResetAuthentication = {
    event: "reset_authentication";
    user: DataLayerUser;
};

type DataLayerEventClickLink = {
    event: "click_link";
    container: TrackingData["container"];
    link: {
        target: string;
        text: string | null;
        url: string | null;
    };
};

type DataLayerEventViewModal = {
    event: "view_modal";
    container: TrackingData["container"];
};

type DataLayerUser = {
    id?: string;
    emailHash?: string;
    type: "lead" | "subscriber";
};

type TrackerSearchCollection = {
    collection: keyof SearchableCollections;
    term?: string;
};

type TrackerSearchMap = {
    term?: string;
};

type TrackerSignIn = {
    user: UserView;
};

type TrackerSignUp = {
    user: UserView;
};

type TrackerSubmitForm = {
    formReference: string;
    container?: TrackingData["container"];
};

type TrackerConfirmAuthentication = {
    user: UserView;
};

export type TrackerClickLink = {
    container: TrackingData["container"];
    link: {
        target: string;
        text?: string;
        url?: string;
    };
};

type TrackerViewModal = {
    container: TrackingData["container"];
};

class Tracker {
    error(error: Error | {message: string; error: any}) {
        if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED === "true") {
            const wrapped = error instanceof Error ? error : new Error(error.message || error.error);

            Bugsnag.notify(wrapped, (event) => {
                event.addMetadata("cause", error);
            });
        }
    }

    clickLink({container, link}: TrackerClickLink) {
        this._push({
            event: "click_link",
            container,
            link: {
                target: link.target,
                text: link.text || null,
                url: link.url || null,
            },
        });
    }

    viewModal({container}: TrackerViewModal) {
        this._push({
            event: "view_modal",
            container,
        });
    }

    searchCollection({collection, term}: TrackerSearchCollection) {
        if (term) {
            this._push({
                event: "search",
                search_collection: collection,
                search_term: term,
                search_type: "collection",
            });
        }
    }

    searchMap({term}: TrackerSearchMap) {
        if (term) {
            this._push({
                event: "search",
                search_term: term,
                search_type: "map",
            });
        }
    }

    submitForm({formReference, container}: TrackerSubmitForm) {
        this._push({
            event: "generate_lead",
            currency: "EUR",
            value: 0,
            form_reference: formReference,
            container: container || null,
        });
    }

    async signIn({user}: TrackerSignIn) {
        this._push({
            event: "login",
            user: {
                id: user.id,
                emailHash: await this._getEmailHash(user.email),
                type: user.formula ? "subscriber" : "lead",
            },
        });
    }

    signOut() {
        this._push({
            event: "logout",
            user: {id: undefined, type: "lead"},
        });
    }

    async signUp({user}: TrackerSignUp) {
        this._push({
            event: "sign_up",
            user: {
                id: user.id,
                emailHash: await this._getEmailHash(user.email),
                type: "lead",
            },
        });
    }

    async confirmAuthentication({user}: TrackerConfirmAuthentication) {
        this._push({
            event: "confirm_authentication",
            user: {
                id: user.id,
                emailHash: await this._getEmailHash(user.email),
                type: user.formula ? "subscriber" : "lead",
            },
        });
    }

    resetAuthentication() {
        this._push({
            event: "reset_authentication",
            user: {id: undefined, type: "lead"},
        });
    }

    page(path: string) {
        if (path !== "/abonnement" && path !== "/boutique" && path !== "/carte-cadeau") return;

        const eventLabels = {
            "/abonnement": "subscriptionFunnel",
            "/boutique": "shopFunnel",
            "/carte-cadeau": "giftcardFunnel",
        };

        this._push({
            event: "screenInteraction",
            checkout_category: "outsideFunnel",
            event_action: "step0",
            event_label: eventLabels[path],
        });
    }

    filterOfferings(filterName: string, channel: Channel, initial: boolean) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, initial),
            event_action: `filterOfferings`,
            event_label: `${filterName}`,
        });
    }

    addToCart(
        offering: OfferingView,
        quantity: number,
        place: PlaceView,
        channel: Channel,
        options?: {isConfirm?: boolean; isSuggestion: boolean},
    ) {
        const {isConfirm, isSuggestion} = options || {};
        const eventLabel = (!isConfirm ? "step4" : "step5") + (isSuggestion ? "_suggestion" : "");
        this._push({
            event: "addToCart",
            checkout_category: this._getCheckoutCategory(channel, false),
            event_label: eventLabel,
            ecommerce: {
                currencyCode: "EUR",
                add: {
                    actionField: {
                        shipping: this._getFormattedShipping(place),
                    },
                    products: this._offeringToProducts(offering, quantity),
                },
            },
        });
    }

    removeFromCart(
        offering: OfferingView,
        quantity: number,
        place: PlaceView,
        channel: Channel,
        options?: {isConfirm?: boolean},
    ) {
        const {isConfirm} = options || {};
        this._push({
            event: "removeFromCart",
            checkout_category: this._getCheckoutCategory(channel, false),
            event_label: !isConfirm ? "step4" : "step5",
            ecommerce: {
                currencyCode: "EUR",
                remove: {
                    actionField: {
                        shipping: this._getFormattedShipping(place),
                    },
                    products: this._offeringToProducts(offering, quantity),
                },
            },
        });
    }

    submitCart(cart: CartView, channel: Channel) {
        this._push({
            event: "checkout",
            checkout_category: this._getCheckoutCategory(channel, false),
            event_label: channel === RestrictedChannel.Combo ? "step5" : "step6",
            ecommerce: {
                currencyCode: "EUR",
                checkout: {
                    actionField: {
                        shipping: this._getFormattedShipping(cart.delivery.place),
                    },
                    products: this._offeringsToProducts(cart.offerings),
                },
            },
        });
    }

    purchased(sale: SaleView, channel: Channel) {
        const price = this._getSaleTotal(sale);

        this._push({
            event: "orderComplete",
            checkout_category: this._getCheckoutCategory(channel, false),
            event_label: channel === RestrictedChannel.Combo ? "step6" : "step7",
            ecommerce: {
                currencyCode: "EUR",
                purchase: {
                    actionField: {
                        id: sale.id,
                        tax: this._getFormattedTax(sale),
                        revenue: this._formatPrice(price),
                        shipping: this._getFormattedShipping(sale.delivery.place),
                    },
                    products: this._offeringsToProducts(sale.offerings),
                },
            },
        });
    }

    refund(sale: SaleView, channel: Channel) {
        return this.purchased(sale, channel);
    }

    addToSubscriptionCart(offering: OfferingView, quantity: number, place: PlaceView) {
        this._push({
            event: "addToCart",
            checkout_category: "lockerFunnel",
            event_label: "step1",
            ecommerce: {
                currencyCode: "EUR",
                add: {
                    actionField: {
                        shipping: this._getFormattedShipping(place),
                    },
                    products: this._offeringToProducts(offering, quantity),
                },
            },
        });
    }

    removeFromSubscriptionCart(offering: OfferingView, quantity: number, place: PlaceView) {
        this._push({
            event: "removeFromCart",
            checkout_category: "lockerFunnel",
            event_label: "step1",
            ecommerce: {
                currencyCode: "EUR",
                remove: {
                    actionField: {
                        shipping: this._getFormattedShipping(place),
                    },
                    products: this._offeringToProducts(offering, quantity),
                },
            },
        });
    }

    submitSubscriptionCart(offerings: OfferingView[], place: PlaceView) {
        this._push({
            event: "checkout",
            checkout_category: "lockerFunnel",
            event_label: "step2",
            ecommerce: {
                currencyCode: "EUR",
                checkout: {
                    actionField: {
                        shipping: this._getFormattedShipping(place),
                    },
                    products: this._offeringsToProducts(offerings),
                },
            },
        });
    }

    booked(sale: SaleView, subscription: SubscriptionView, shippingAt: string) {
        this._push({
            event: "orderComplete",
            checkout_category: "lockerFunnel",
            event_label: "step3",
            ecommerce: {
                currencyCode: "EUR",
                purchase: {
                    actionField: {
                        id: `${subscription.id}-${shippingAt}`,
                        tax: this._getFormattedTax(sale),
                        revenue: this._formatPrice(sale.payment.price.ttc),
                        shipping: this._getFormattedShipping(sale.delivery.place),
                    },
                    products: this._offeringsToProducts(sale.offerings),
                },
            },
        });
    }

    updateSubscriptionRate(rate: number) {
        this._push({
            event: "screenInteraction",
            checkout_category: "subscriptionFunnel",
            event_action: "rate",
            event_label: "step1",
            subscription_rate: rate,
        });
    }

    updateSubscriptionQuantity(quantity: number, isConfirm: boolean) {
        this._push({
            event: "screenInteraction",
            checkout_category: "subscriptionFunnel",
            event_action: "quantity",
            event_label: !isConfirm ? "step2" : "step8",
            subscription_quantity: quantity,
        });
    }

    updateSubscriptionAllergies(allergies: string) {
        this._push({
            event: "screenInteraction",
            checkout_category: "subscriptionFunnel",
            event_action: "allergies",
            event_label: "step3",
            subscription_allergies: allergies,
        });
    }

    searchPlaces(channel: Channel, search: string) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, true),
            event_action: "searchPlace",
            event_label: channel === RestrictedChannel.Combo ? "step4" : "step1",
            place_search: search,
        });
    }

    revealHomePlaces(channel: Channel) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, true),
            event_action: "revealHomePlaces",
            event_label: channel === RestrictedChannel.Combo ? "step5_home1" : "step2_home1",
        });
    }

    searchHomeLocation(channel: Channel, lookup: Lookup) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, true),
            event_action: "searchHomeLocation",
            event_label: channel === RestrictedChannel.Combo ? "step5_home2" : "step2_home2",
            place_search_street: lookup.street,
            place_search_city: lookup.city,
            place_search_zipcode: lookup.zipcode,
            place_search_details: lookup.details,
        });
    }

    selectHomeLocation(channel: Channel, location: string) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, true),
            event_action: "selectHomeLocation",
            event_label: channel === RestrictedChannel.Combo ? "step5_home3" : "step2_home3",
            place_location: location,
        });
    }

    selectPlace(channel: Channel, place: PlaceView | undefined, search: string) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, true),
            event_action: "viewPlace",
            event_label: channel === RestrictedChannel.Combo ? "step5" : "step2",
            place_search: search,
            place_id: place?.id,
            place_name: place?.name,
            place_location: place?.description,
        });
    }

    updatePlace(channel: Channel, place: PlaceView, search: string) {
        this._push({
            event: "screenInteraction",
            checkout_category: this._getCheckoutCategory(channel, true),
            event_action: "selectPlace",
            event_label: channel === RestrictedChannel.Combo ? "step6" : "step3",
            place_search: search,
            place_id: place?.id,
            place_name: place?.name,
            place_location: place?.description,
            place_opening_day: place?.opening && _.getFormattedDay(place.opening.day),
        });
    }

    addSubscription(subscription: SubscriptionView) {
        this._push({
            event: "addToCart",
            checkout_category: "subscriptionFunnel",
            event_label: "step7",
            ecommerce: {
                currencyCode: "EUR",
                add: {
                    actionField: {
                        shipping: this._getFormattedShipping(subscription.place),
                    },
                    products: this._subscriptionToProduct(subscription),
                },
            },
        });
    }

    submitSubscription(subscription: SubscriptionView) {
        this._push({
            event: "checkout",
            checkout_category: "subscriptionFunnel",
            event_label: "step9",
            ecommerce: {
                currencyCode: "EUR",
                checkout: {
                    actionField: {
                        shipping: this._getFormattedShipping(subscription.place),
                    },
                    products: this._subscriptionToProduct(subscription),
                },
            },
        });
    }

    subscribed(subscription: SubscriptionView) {
        this._push({
            event: "orderComplete",
            checkout_category: "subscriptionFunnel",
            event_label: "step10",
            ecommerce: {
                currencyCode: "EUR",
                purchase: {
                    actionField: {
                        id: subscription.id,
                        tax: this._getFormattedSubscriptionTax(subscription.place, subscription.price),
                        revenue: this._getFormattedSubscriptionTotal(subscription),
                        shipping: this._getFormattedShipping(subscription.place),
                    },
                    products: this._subscriptionToProduct(subscription),
                },
            },
        });
    }

    unsubscribed(user: UserView) {
        this._push({
            event: "unsubscribe",
            user: {id: user.id},
        });
    }

    updatedGiftCardComboOffered(comboOffered: number) {
        this._push({
            event: "screenInteraction",
            checkout_category: "giftcardFunnel",
            event_action: "quantity",
            event_label: "step1",
            giftcard_quantity: comboOffered,
        });
    }

    addGiftCardToCart(price: number, comboOffered: number) {
        this._push({
            event: "addToCart",
            checkout_category: "giftcardFunnel",
            event_label: "step4",
            ecommerce: {
                currencyCode: "EUR",
                add: {
                    actionField: {
                        shipping: "0.00",
                    },
                    products: {
                        id: "giftcard",
                        name: "Carte cadeau",
                        price: this._formatPrice(price),
                        quantity: comboOffered,
                    },
                },
            },
        });
    }

    purchasedGiftCard(giftCard: GiftCardView) {
        this._push({
            event: "orderComplete",
            checkout_category: "giftCardFunnel",
            event_label: "step5",
            ecommerce: {
                currencyCode: "EUR",
                purchase: {
                    actionField: {
                        id: giftCard.id,
                        tax: this._getFormattedTax(giftCard),
                        revenue: this._formatPrice(giftCard.payment.price.ttc),
                        shipping: "0.00",
                    },
                    products: {
                        id: "giftcard",
                        name: "Carte cadeau",
                        price: this._formatPrice(giftCard.payment.price.ttc),
                        quantity: giftCard.coupon.comboDiscountedCount,
                    },
                },
            },
        });
    }

    _offeringsToProducts(offerings: OfferingView[]) {
        let products: {id: string; name: string; quantity?: number}[] = [];
        offerings.forEach((offering) => {
            products = products.concat(this._offeringToProducts(offering));
        });
        return products;
    }

    _offeringToProducts(offering: OfferingView, quantity?: number) {
        const products: {id: string; name: string; quantity?: number}[] = [];
        offering.items.forEach((item) => {
            products.push({
                id: `${offering.id}-${item.product.id}`,
                name: `${_.getItemSimpleName(item)}${item.meta?.caliber ? ", " + item.meta.caliber : ""}`,
                quantity: quantity || offering.quantity || offering.count, // handle offering (quantity) and saleOffering (count)
            });
        });
        return products;
    }

    _subscriptionToProduct(subscription: SubscriptionView) {
        return [
            {
                id: "subscription",
                name: "Le Casier de la mer",
                price: this._formatPrice(subscription.price),
                variant: subscription.rate,
                quantity: subscription.quantity,
            },
        ];
    }

    _getFormattedShipping(place?: PlaceView) {
        let shipping = 0;
        if (place?.opening?.extra) shipping += place.opening.extra;
        return this._formatPrice(shipping);
    }

    _getFormattedTax(sale: SaleView | GiftCardView) {
        return this._formatPrice(sale.payment.price.ttc - sale.payment.price.ht);
    }

    _getFormattedSubscriptionTax(place: PlaceView | undefined, price: number) {
        let shipping = 0;
        if (place?.opening?.extra) shipping += place.opening.extra;

        let tax = price - Math.round(price / 1.055);
        tax += shipping - Math.round(shipping / 1.2);
        return this._formatPrice(tax);
    }

    _getFormattedSubscriptionTotal(subscription: SubscriptionView) {
        let price = subscription.price * subscription.quantity;
        if (subscription?.place?.opening?.extra) price += subscription.place.opening.extra;
        return this._formatPrice(price);
    }

    _getSaleTotal(sale: SaleView) {
        return sale.payment.price.ttc;
    }

    _getCheckoutCategory(channel: Channel, initial: boolean) {
        if (channel === RestrictedChannel.Combo) return initial ? "subscriptionFunnel" : "plusFunnel";

        return "shopFunnel";
    }

    _formatPrice(price: number) {
        return (price / 100).toFixed(2);
    }

    async _getEmailHash(email: string): Promise<string> {
        const cleanedEmail = email.trim().toLowerCase();
        const msgUint8 = new TextEncoder().encode(cleanedEmail); // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
        return hashHex;
    }

    _push(event: DataLayerObject) {
        if (!process.env.NEXT_PUBLIC_TRACKING_GOOGLE_TAG_MANAGER) return;

        try {
            sendGTMEvent(event);
        } catch (err) {
            console.error(err);
        }
    }
}

export default Tracker;
export const tracker = new Tracker();
